@import url("https://fonts.googleapis.com/css2?family=Michroma&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Michroma", sans-serif;
  }
  p {
    font-family: "Quicksand", Times, serif;
  }
  button {
    font-family: "Quicksand", Times, serif;
  }
}
